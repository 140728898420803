<template>
  <div class="app-content">
    <el-card class="box-card" v-loading="loading">
      <div class="clearfix">
        <el-row>
          <el-col :span="24">
            <el-form :inline="true">
              <el-form-item label="企业名称">
                <el-select
                  v-model="query.name"
                  filterable
                  remote
                  clearable
                  :remote-method="remoteMethod"
                  placeholder="请选择查询企业"
                >
                  <el-option
                    v-for="item in Enterprise"
                    :key="item.id"
                    :label="item.name"
                    :value="item.name"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="监测类型">
                <el-select
                  v-model="query.type"
                  filterable
                  placeholder="请选择监测类型"
                  @change="getType"
                >
                  <el-option
                    v-for="item in type"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="表单类型">
                <el-select
                  v-model="query.type_id"
                  filterable
                  placeholder="请选择表单类型"
                  clearable
                >
                  <el-option label="全部" value=""></el-option>
                  <el-option
                    v-for="item in formType"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="签到时间">
                <el-date-picker
                  v-model="dayValue"
                  type="daterange"
                  value-format="yyyy-MM-dd"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  @change="onDay"
                ></el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="onQuerySubmit"
                  >查询</el-button
                >
                <el-button type="primary" @click="downloadAll">导出</el-button>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
      </div>
      <div class="block">
        <el-row>
          <el-col :span="24">
            <el-table :data="Data.data" stripe style="width: 100%">
              <el-table-column prop="" label="头像" width="150">
                <template slot-scope="scope">
                  <el-avatar
                    :src="config.storage + scope.row.avatar_url"
                  ></el-avatar>
                </template>
              </el-table-column>
              <el-table-column
                prop="user_name"
                label="运维人员"
                min-width="100"
              >
              </el-table-column>
              <el-table-column
                prop="name"
                label="企业名称"
                min-width="200"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                prop="form_name"
                label="表单类型"
                min-width="180"
              >
              </el-table-column>
              <el-table-column
                prop="arrive_time"
                label="签到时间"
                min-width="180"
              >
              </el-table-column>

              <el-table-column fixed="right" label="操作" width="100">
                <template slot-scope="scope">
                  <el-button
                    @click="userDatail(scope.row)"
                    type="text"
                    size="small"
                    >详情</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
        <el-pagination
          layout="prev, pager, next"
          prev-text="上一页"
          next-text="下一页"
          background
          :page-size="10"
          @current-change="changeCurrent"
          :current-page="query.page"
          :total="query.total"
        >
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import {
  apiFormRecordTypeList,
  apiOutletEnterpriseList,
  apiFormRecordList,
  apiFormRecordMonitor,
  apiFormRecordPdf
} from "src/api/index";
import md5 from "js-md5";
export default {
  components: {},
  data() {
    return {
      loading: false,
      Data: {},
      query: {
        page: 1,
        name: "",
        type: "",
        type_id: "",
        start_time: "",
        end_time: "",
        total: 0,
        logotype: "maintainSignin"
      },
      type: [],
      formType: [],
      Enterprise: {},
      dayValue: ""
    };
  },
  mounted() {
    const _this = this;
    let query = JSON.parse(_this.$localStorage.get(md5("query")));
    if (query === null) {
      _this.query = _this.query;
    } else if (query.logotype === "maintainSignin") {
      _this.query = query;
      _this.dayValue = [query.start_time, query.end_time];
    } else {
      _this.$localStorage.remove(md5("query"));
    }
    //获取表单类型
    apiFormRecordMonitor()
      .then(lists => {
        _this.type = lists;
        if (!_this.query.type) {
          _this.query.type = lists[0].id;
        }
        _this.getType();
      })
      .catch(err => {
        _this.error(err);
      });
    //获取企业列表
    apiOutletEnterpriseList()
      .then(lists => {
        _this.Enterprise = lists.data;
      })
      .catch(err => {
        _this.error(err);
      });
    _this.changeCurrent(_this.query.page);
    _this.pageInit(_this).then(() => {});
  },
  computed: mapState({
    permission: state => state.permission,
    url: state => state.url,
    config: state => state.config
  }),
  watch: {
    $route: "getListData"
  },
  methods: {
    ...mapActions(["pageInit"]),
    //查询签到记录
    onQuerySubmit() {
      this.query.page = 1;
      let route =
        this.$router.history.current.path +
        "?page=" +
        this.query.page +
        "&name=" +
        this.query.name +
        "&type=" +
        this.query.type +
        "&type_id=" +
        this.query.type_id +
        "&start_time=" +
        this.query.start_time +
        "&end_time=" +
        this.query.end_time;
      let locat = decodeURI(this.$router.history.current.fullPath);
      if (route === locat) {
        this.getListData();
      } else {
        this.$router.replace(route);
      }
    },
    // 动态加载
    remoteMethod(query) {
      const _this = this;
      // if (query.trim()) {
      apiOutletEnterpriseList({ name: query }).then(lists => {
        _this.Enterprise = lists.data;
      });
    },
    //获取表单类型
    getType() {
      const _this = this;
      apiFormRecordTypeList({ type_id: _this.query.type })
        .then(lists => {
          _this.formType = lists;
        })
        .catch(err => {
          _this.error(err);
        });
    },
    onDay() {
      const _this = this;
      if (_this.dayValue) {
        _this.query.start_time = _this.dayValue[0];
        _this.query.end_time = _this.dayValue[1];
      } else {
        _this.query.start_time = "";
        _this.query.end_time = "";
      }
    },

    //切换分页
    changeCurrent(item) {
      this.query.page = item;
      let route =
        this.$router.history.current.path +
        "?page=" +
        this.query.page +
        "&name=" +
        this.query.name +
        "&type=" +
        this.query.type +
        "&type_id=" +
        this.query.type_id +
        "&start_time=" +
        this.query.start_time +
        "&end_time=" +
        this.query.end_time;
      let locat = decodeURI(this.$router.history.current.fullPath);
      if (route === locat) {
        this.getListData();
      } else {
        this.$router.replace(route);
      }
    },
    //获取签到记录列表
    getListData() {
      const _this = this;
      _this.loading = true;
      _this.Data = {};
      apiFormRecordList(_this.query)
        .then(res => {
          _this.Data = res;
          _this.query.total = res.total;
          _this.loading = false;
        })
        .catch(err => {
          _this.loading = false;
          _this.error(err);
        });
    },
    //跳转台账详情页
    userDatail(item) {
      this.$localStorage.set(md5("query"), JSON.stringify(this.query));
      this.$router.push("/admin/maintain/formScreen-detail/" + item.id);
    },
    //导出
    downloadAll() {
      const _this = this;
      if (!_this.query.type_id) {
        this.$message.error("请先选择表单类型");
        return;
      }
      apiFormRecordPdf(_this.query)
        .then(res => {
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = _this.config.storage + res.url;
          link.setAttribute("target", "_blank");
          document.body.appendChild(link);
          link.click();
        })
        .catch(err => {
          _this.$message.error(err.message);
        });
    },
    //错误信息提示
    error(err) {
      if (err.message === "没有操作权限") {
        return;
      } else {
        this.$message.error(err.message);
      }
    }
  }
};
</script>
<style lang="less" scoped>
.app-content {
  background-color: #ffffff;
}
.el-pagination {
  text-align: center;
  margin-top: 27px;
}
.el-avatar--circle {
  position: relative;
  margin-top: 7px;
}
/deep/ .el-table--small td,
.el-table--small th {
  padding: 0;
}
/deep/ .el-table td,
.el-table th {
  padding: 0;
}
/deep/ .is-leaf {
  padding: 8px 0;
}
</style>
